const BACK_END_PATH = 'https://get-healthy-434aa91a5b0f.herokuapp.com';
const BACK_END_WATER_TRACKER = `${BACK_END_PATH}/water-tracker`;
const BACK_END_USER = `${BACK_END_PATH}/user`;

// limitations for daily values
const MIN_GOAL = 500;
const MAX_GOAL = 9000;
const MIN_AMOUNT = 0;
const MAX_AMOUNT = 9000;
const MIN_CONTROL_AMOUNT = 100;

// Additional time in ms for MUI loading animation
const timerValue = 600;

enum SingleDayKeys {
  date = 'date',
  currentAmount = 'currentAmount',
  currentGoal = 'currentGoal',
}

enum ControlKeys {
  amount = 'amount',
  goal = 'goal',
  type = 'type',
}

enum ArrowDirections {
  top = 'top',
  bottom = 'bottom',
  right = 'right',
  left = 'left',
}

enum DigitsIndex {
  thousand = 4,
  hundred = 3,
  tens = 2,
  ones = 1,
}

enum Themes {
  light = 'light',
  dark = 'dark',
  system = 'system',
}

const TODAY = new Date();
const CURRENT_YEAR = TODAY.getFullYear();
const CURRENT_MONTH = TODAY.getMonth();
const CURRENT_DATE = TODAY.getDate();

const HOURS = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

const WEEKDAY_FULL_NAMES = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const COLOR_PALETTE = [
  '#277DA1',
  '#264653',
  '#2A9D8F',
  '#E9C46A',
  '#F4A261',
  '#E76F51',
  '#577590',
  '#219EBC',
  '#9bf6ff',
  '#d35fa1',
  '#577590',
];

export {
  BACK_END_PATH,
  BACK_END_WATER_TRACKER,
  BACK_END_USER,
  MIN_GOAL,
  MAX_GOAL,
  MIN_AMOUNT,
  MAX_AMOUNT,
  MIN_CONTROL_AMOUNT,
  TODAY,
  CURRENT_DATE,
  CURRENT_MONTH,
  CURRENT_YEAR,
  HOURS,
  WEEKDAY_FULL_NAMES,
  COLOR_PALETTE,
  timerValue,
  SingleDayKeys,
  ControlKeys,
  ArrowDirections,
  DigitsIndex,
  Themes,
};
