import { useDay } from './Day.hook';
import { v4 } from 'uuid';
import { Grid, Skeleton } from '@mui/material';
import { MobileSlider } from './MobileSlider/MobileSlider';
import { StatusCircle } from '../../Today/StatusCircle/StatusCircle';
import NotFound from '../../../404/NotFound';
import { HourlyTypes } from './Charts/HourlyTypes/HourlyTypes';
import { LiquidTypes } from './LiquidTypes';
import { HourlyAmounts } from './Charts/HourlyAmounts/HourlyAmounts';
import classes from './Day.module.scss';
import '../../../../Styles/main.scss';

/**
 * Component to display single day data
 */
const Day: React.FC = () => {
  const day = useDay();

  const slides = [
    <div key={v4()}>
      <div className={`${classes['slide']} content-background box-shadow`}>
        <div
          className={` ${classes['progress-bar-container']} flex flex-col justify-center items-center`}
        >
          <h3 className={`${classes['title']}`}>
            {day.data?.date ? day.data.date : ''}
          </h3>
          <StatusCircle day={day.data} />
        </div>
      </div>
    </div>,
    <div key={v4()}>
      <div
        className={`${classes['slide']} content-background box-shadow`}
        key={v4()}
      >
        <HourlyAmounts day={day.data} />
      </div>
    </div>,
    <div key={v4()}>
      <div
        className={`${classes['slide']} content-background box-shadow`}
        key={v4()}
      >
        <HourlyTypes colors={day.COLORS} day={day.data} />
      </div>
    </div>,
    <div key={v4()}>
      <div
        className={`${classes['slide']} content-background box-shadow`}
        key={v4()}
      >
        <LiquidTypes colors={day.COLORS} day={day.data} />
      </div>
    </div>,
  ];

  const allChartsView = (
    <Grid container alignItems={'center'}>
      <Grid item lg={6} xs={12}>
        <div
          className={`${classes['progress-bar-container']} flex flex-col justify-center items-center`}
        >
          {day.isLoading ? (
            <Skeleton
              className="mui-skeleton"
              style={{ marginBottom: '0.75rem' }}
              variant="rounded"
              width={'10rem'}
              height={'1.4rem'}
            />
          ) : (
            <h3 className={`${classes['title']}`}>{day.title}</h3>
          )}
          <StatusCircle day={day.data} />
        </div>
      </Grid>
      <Grid item lg={6} xs={12}>
        <HourlyAmounts day={day.data} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <LiquidTypes colors={day.COLORS} day={day.data} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <HourlyTypes colors={day.COLORS} day={day.data} />
      </Grid>
    </Grid>
  );

  const button = (
    <button
      className={`${classes['go-back-button']} highlighted-content-area`}
      onClick={day.handleGoBack}
    >
      <svg
        className={`${classes['go-back-icon']} `}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
      </svg>
    </button>
  );

  const content = day.isMobile ? (
    <div className={`${classes['container']}`}>
      {button}
      <MobileSlider slides={slides} />
    </div>
  ) : (
    <div className={`${classes['container']}`}>
      <div
        className={`${classes['lg-container']} content-background box-shadow`}
      >
        {button}
        {allChartsView}
      </div>
    </div>
  );

  return day.match ? content : <NotFound />;
};

export { Day };
