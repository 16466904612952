import React from 'react';
import { useRedirectToProtected } from '../../../Hooks/Redirect';
import { useSignUp } from './SignUp.hooks';
import { NavLink } from 'react-router-dom';
import { Box, Button, CssBaseline, Grid, TextField } from '@mui/material';
import { LanguagePicker } from '../../UI/LanguagePicker/LanguagePicker';
import { ThemePicker } from '../../UI/ThemePicker/ThemePicker';
import RoutesValues from '../../../Routes/Routes.values';
import LoadingOverlay from '../../Overlays/LoadingOverlay/LoadingOverlay';
import { boxStyles } from '../Athentication.constants';
import classes from '../Authentication.module.scss';
import '../../../Styles/main.scss';

const SignUp: React.FC = () => {
  // get object with all sign up functions and states
  const signUp = useSignUp();

  useRedirectToProtected();

  return !signUp.currentUser && signUp.userIsLoading ? (
    <LoadingOverlay />
  ) : (
    <div className={`${classes['relative']}`}>
      <CssBaseline />
      <Box sx={boxStyles}>
        <Box
          component="form"
          noValidate
          onSubmit={signUp.handleSubmit}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="mui-input"
                variant="standard"
                {...signUp.nameInputProps}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="mui-input"
                variant="standard"
                {...signUp.emailInputProps}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="mb-4">
                <TextField
                  className="mui-input"
                  variant="standard"
                  {...signUp.passwordInputProps}
                />
              </div>
            </Grid>
          </Grid>
          <Button
            className="mui-button"
            type="submit"
            fullWidth
            variant="outlined"
          >
            {signUp.translations.signup}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item sx={{ mt: 2 }}>
              <NavLink to={RoutesValues.signIn}>
                {signUp.translations.question}
              </NavLink>
            </Grid>
          </Grid>
          {signUp.errorMessage && (
            <div className={classes['error-message']}>
              {signUp.translations.conflict}
            </div>
          )}
        </Box>
      </Box>
      <ThemePicker />
      <LanguagePicker />
    </div>
  );
};

export default SignUp;
