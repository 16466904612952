import React from 'react';
import { useMonthHeader } from './MonthHeader.hooks';
import { v4 } from 'uuid';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  List,
  ListItem,
  Skeleton,
} from '@mui/material';
import { TimeTraverseModal } from '../Modals/TimeTraverseModal/TimeTraverseModal';
import { Statistics } from '../DataToView/Statistics/Statistics';
import { ExtendedStatistics } from '../DataToView/Statistics/ExtendedStatistics';
import classes from './MonthHeader.module.scss';
import '.././/../../Styles/main.scss';

interface MonthControlsProps {
  date: Date;
}

/**
 * MonthControls component for month overview and time navigation
 */
const MonthHeader: React.FC<MonthControlsProps> = ({ date }) => {
  // Get all necessary data and functions for month controls
  const monthControls = useMonthHeader(date);

  // Loading skeleton for the list of drinks
  const loadingList = (
    <List className={`${classes['list']}`}>
      {[1, 2, 3, 4, 5, 6, 7].map((_, i, arr) => (
        <React.Fragment key={`${i}_empty_drink_placeholder`}>
          <ListItem className={`${classes['list-item']}`}>
            <Skeleton
              className="mui-skeleton"
              variant="text"
              width={`${monthControls.isMobile ? 10 : 17}rem`}
              height={'1.5rem'}
            />
            <Skeleton
              className="mui-skeleton"
              variant="text"
              width={`2.25rem`}
              height={'1.5rem'}
            />
          </ListItem>
          {i < arr.length - 1 && (
            <Divider
              className="mui-divider"
              orientation={'horizontal'}
              variant={'middle'}
              component={'li'}
            />
          )}
        </React.Fragment>
      ))}
    </List>
  );

  // Create list of drinks
  const list = (
    <React.Fragment>
      {monthControls.drinks.length > 0 ? (
        <List className={`${classes['list']}  ${classes['scroll-way']}`}>
          {monthControls.drinks.map((drink, i, arr) => (
            <React.Fragment key={`${v4()}`}>
              <ListItem className={`${classes['list-item']}`}>
                <span className={`${classes['list-item__type']}`}>{`${i + 1}. ${
                  drink.type
                }`}</span>
                <span>{`${drink.amount} ${monthControls.translations.l}`}</span>
              </ListItem>
              {i < arr.length - 1 && (
                <Divider
                  className="mui-divider"
                  orientation={'horizontal'}
                  variant={'middle'}
                  component={'li'}
                />
              )}
            </React.Fragment>
          ))}
        </List>
      ) : (
        <div className={`${classes['no-drinks']}`}>
          <svg
            className={`${classes['no-drinks__icon']}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM182.4 382.5c-12.4 5.2-26.5-4.1-21.1-16.4c16-36.6 52.4-62.1 94.8-62.1s78.8 25.6 94.8 62.1c5.4 12.3-8.7 21.6-21.1 16.4c-22.4-9.5-47.4-14.8-73.7-14.8s-51.3 5.3-73.7 14.8zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
          </svg>
          <p className={`${classes['no-drinks__text']}`}>
            {monthControls.translations.noDrinks}
          </p>
        </div>
      )}
    </React.Fragment>
  );

  const empty = (
    <div className={`${classes['no-drinks']}`}>
      <svg
        className={`${classes['no-drinks__icon']}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM182.4 382.5c-12.4 5.2-26.5-4.1-21.1-16.4c16-36.6 52.4-62.1 94.8-62.1s78.8 25.6 94.8 62.1c5.4 12.3-8.7 21.6-21.1 16.4c-22.4-9.5-47.4-14.8-73.7-14.8s-51.3 5.3-73.7 14.8zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
      </svg>
      <p className={`${classes['no-drinks__text']}`}>
        {monthControls.translations.noDrinks}
      </p>
    </div>
  );

  const listOrEmpty = monthControls.drinks.length > 0 ? list : empty;

  // Create accordion with drinks for mobile view
  const accordionWithDrinks = (
    <Accordion className={`${classes['accordion']}`}>
      <AccordionSummary
        expandIcon={
          <div className={`${classes['circle']}`}>
            <svg
              className={`${classes['icon']}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
            </svg>
          </div>
        }
        aria-controls="panel-content"
        id="panel-header"
      >
        <div className={`${classes['description']}`}>
          {monthControls.translations.drinksListTitle}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {monthControls.isLoading ? loadingList : list}
      </AccordionDetails>
    </Accordion>
  );

  // Create drink list with scrollable container for large screens
  const drinkList = (
    <div
      className={`${classes['list__background']} highlighted-content-background`}
    >
      <OverlayScrollbarsComponent
        className="mx-2"
        element="div"
        defer
        options={{
          scrollbars: {
            dragScroll: true,
            clickScroll: 'instant',
            visibility: 'visible',
          },
          showNativeOverlaidScrollbars: false,
        }}
      >
        <div className={`${classes['legend-container']}`}>
          {monthControls.isLoading ? loadingList : listOrEmpty}
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );

  // Choose content based on screen size
  const listContent = monthControls.isMobile ? accordionWithDrinks : drinkList;

  return (
    <>
      {monthControls.showTimeTraverse && (
        <TimeTraverseModal closeModal={monthControls.closeModal} />
      )}
      <Grid item xs={12}>
        <div
          className={`${classes['month-controls__wrapper']} content-background box-shadow`}
        >
          <Grid container>
            <Grid item lg={6} xs={12}>
              <div className={classes['month-controls-container']}>
                <div className={classes['header-container']}>
                  {monthControls.isLoading ? (
                    <Skeleton
                      className="mui-skeleton"
                      variant="rounded"
                      width={'10rem'}
                      height={'1.8rem'}
                    />
                  ) : (
                    <h3 className={classes['header']}>
                      {monthControls.translations.title}
                    </h3>
                  )}
                </div>
                <div className={`flex justify-center align-center`}>
                  {monthControls.isLoading ? (
                    <Skeleton
                      className="mui-skeleton mt-8"
                      variant="rounded"
                      width={'14rem'}
                      height={'2.75rem'}
                    />
                  ) : (
                    <div
                      onClick={monthControls.openModal}
                      className={classes['button']}
                    >
                      <div className={classes['button-output']}>
                        {monthControls.translations.navigate}
                      </div>
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  <Statistics {...monthControls.statisticsConfig} />
                </div>
                <ExtendedStatistics
                  {...monthControls.extendedStatisticsConfig}
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              {!monthControls.isMobile && (
                <h5 className={classes['sub-header']}>
                  {monthControls.translations.drinksListTitle}
                </h5>
              )}
              {!monthControls.isMobile && (
                <Divider
                  className="mui-divider"
                  style={{ margin: '0.5rem 1rem' }}
                  orientation={'horizontal'}
                  variant={'middle'}
                  component={'div'}
                />
              )}
              {listContent}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default MonthHeader;
