import React from 'react';
import { useScreenWidth } from '../../../Providers/ScreenWidth';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Reducers/Store';
import { COLOR_PALETTE, TODAY } from '../../../Constants';
import { getTodayFromWeek } from './Today.utils';
import {
  changeDateFormat,
  formatDateToString,
  getWeekFromDate,
} from '../../../Utils/Time.utils';
import { generateRandomRGB } from '../../../Utils/Color.utils';
import { BREAKPOINT_SLIDER } from '../DataToView/Day/Charts/Charts.constants';
import { useTranslation } from 'react-i18next';

// Breakpoint for turning off the background of today panel
const BREAKPOINT_LARGE = 992;

const CONTENT_BACKGROUND = 'content-background';
const BOX_SHADOW = 'box-shadow';

const useToday = () => {
  // get translations
  const { t } = useTranslation();
  const translations = {
    today: t('tracker.todayPanel.controls.todayTitle'),
    controls: t('tracker.todayPanel.controls.controlsTitle'),
    type: t('tracker.todayPanel.type'),
    amount: t('tracker.todayPanel.amount'),
  };

  // Local state for amount / type toggle
  const [toggleValue, setToggleValue] = React.useState(true);

  const { controls, month, initialLoading } = useSelector(
    (state: RootState) => state.waterTracker
  );

  // get screen width and show the correct view
  const { screenWidth } = useScreenWidth();

  const isMobile = screenWidth <= BREAKPOINT_SLIDER;

  const contentBackground =
    screenWidth <= BREAKPOINT_LARGE
      ? ''
      : `${CONTENT_BACKGROUND} ${BOX_SHADOW}`;

  // get slider reference
  const sliderRef = React.useRef<any>(null);

  // get current week out of month
  const currentWeek = getWeekFromDate(month, TODAY);

  // get today from current week and set the daily goal
  const day = getTodayFromWeek(currentWeek);

  // generate a set of the liquid types
  const liquidTypes = new Set();
  if (day && day.activity) {
    day.activity.forEach((hourlyData) => {
      if (Array.isArray(hourlyData) && hourlyData.length > 0) {
        hourlyData.forEach((item) => {
          liquidTypes.add(item.type);
        });
      }
    });
  }

  // generate additional colors if the current colors are not enough
  const generateAdditionalColors = (size: number) => {
    const additionalColors = [...COLOR_PALETTE];
    for (let i = 0; i < size; i++) {
      additionalColors.push(generateRandomRGB());
    }
    return additionalColors;
  };

  // Generate additional colors for the liquid types if current colors are not enough
  const COLORS =
    COLOR_PALETTE.length <= liquidTypes.size
      ? COLOR_PALETTE
      : generateAdditionalColors(COLOR_PALETTE.length - liquidTypes.size);

  // control values
  const goal = day?.goal ? day.goal : 0;
  const amount = controls.amount !== null ? controls.amount : 0;
  const type = controls.type ? controls.type : 'there is nothing yet';

  // Show drink modal
  const [showControlEdit, setControlEdit] = React.useState(false);

  // Drink modal local state
  const [drinkModal, setDrinkModal] = React.useState(false);

  /**
   * Open the drink modal
   */
  const openDrinkModal = () => {
    setDrinkModal(true);
  };

  /**
   * Close the drink modal
   */
  const closeDrinkModal = () => {
    setDrinkModal(false);
  };

  /**
   * Cancel the goal edit dialog
   */
  const closeEdit = () => {
    setControlEdit(false);
  };

  /**
   * Open the goal edit dialog
   */
  const openEdit = () => {
    setControlEdit(true);
  };

  // today title
  const todayTitle = `${translations.today}: ${
    day?.date
      ? changeDateFormat(day.date, 'DD.MM.YYYY', 'DD.MM.YY')
      : formatDateToString(TODAY, 'DD.MM.YY')
  }`;

  /**
   * handle click to next slide
   */
  const handleNextClick = () => {
    sliderRef.current?.slickNext();
  };

  /**
   * handle click to previous slide
   */
  const handlePrevClick = () => {
    sliderRef.current?.slickPrev();
  };

  /**
   * Toggle the value of the toggle button
   */
  const toggleButtonHandler = () => {
    setToggleValue(!toggleValue);
  };

  // Box shadow for medium screens
  const boxShadow = screenWidth <= BREAKPOINT_LARGE ? 'box-shadow' : '';

  return {
    showControlEdit,
    todayTitle,
    amount,
    goal,
    type,
    initialLoading,
    COLORS,
    screenWidth,
    isMobile,
    day,
    sliderRef,
    translations,
    toggleValue,
    contentBackground,
    boxShadow,
    handleNextClick,
    handlePrevClick,
    closeEdit,
    openEdit,
    toggleButtonHandler,
    drink: {
      drinkModal,
      openDrinkModal,
      closeDrinkModal,
    },
  };
};

export { useToday };
