import React from 'react';
import { useEditGoal } from './EditGoal.hook';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import BluredOverlay from '../../../Overlays/BluredOverlay/BluredOverlay';
import classes from './EditGoal.module.scss';
import '../../../../Styles/main.scss';

interface EditGoalProps {
  closeEdit: () => void;
}

const EditGoal: React.FC<EditGoalProps> = ({ closeEdit }) => {
  const editGoal = useEditGoal(closeEdit);
  return (
    <BluredOverlay>
      <div className={`${classes['modal-wrapper']} modal-background`}>
        <Grid item xs={12}>
          <h3 className={classes['title']}>{editGoal.translations.title}</h3>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className="mui-input"
              error={editGoal.validate && !editGoal.isAmountValid}
              label={editGoal.translations.minGoal.description}
              variant="standard"
              helperText={editGoal.translations.minGoal.hint}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {editGoal.translations.ml}
                  </InputAdornment>
                ),
              }}
              value={editGoal.todaysGoal ? editGoal.todaysGoal : ''}
              fullWidth
              margin="dense"
              autoComplete="off"
              autoCorrect="off"
              onChange={editGoal.handleChangeAmount}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              className="mui-input"
              error={!editGoal.isGoalValid}
              label={editGoal.translations.todaysGoal.description}
              variant="standard"
              helperText={editGoal.translations.todaysGoal.hint}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {editGoal.translations.ml}
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              autoCorrect="off"
              value={editGoal.minGoal ? editGoal.minGoal : ''}
              fullWidth
              margin="dense"
              onChange={editGoal.handleChangeGoal}
            />
          </Grid>
        </Grid>

        <div className={`${classes['control-buttons-container']} mt-4`}>
          <Button
            className="mui-button"
            onClick={editGoal.handleSubmit}
            variant="outlined"
          >
            {editGoal.translations.buttons.ok}
          </Button>
          <Button
            className="mui-button"
            onClick={editGoal.handleCancelEdit}
            variant="outlined"
          >
            {editGoal.translations.buttons.cancel}
          </Button>
        </div>
      </div>
    </BluredOverlay>
  );
};

export default EditGoal;
