import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import RoutesValues from '../../Routes/Routes.values';
import { getTheme, setTheme } from '../../Utils/Theme';
import classes from './NotFound.module.scss';
import '../../Styles/main.scss';

const NotFound: React.FC = () => {
  // get navigation function
  const navigate = useNavigate();

  // Initialize color theme
  const theme = getTheme();
  setTheme(theme);

  // get translations
  const { t } = useTranslation();
  const translations = {
    notFound: t('404Page.title'),
    toMain: t('404Page.button'),
  };

  // handle navigation to the main page
  const navigateToMainPage = () => {
    navigate(RoutesValues.root);
  };

  return (
    <div className={classes['wrapper']}>
      <h1 className={classes['header']}>404</h1>
      <h3 className={classes['sub-header']}>{translations.notFound}</h3>
      <Button
        className="mui-button"
        variant={'outlined'}
        onClick={navigateToMainPage}
      >
        {translations.toMain}
      </Button>
    </div>
  );
};

export default NotFound;
