import React from 'react';
import { useToday } from './Today.hooks';
import { v4 } from 'uuid';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Drink } from './Drink/Drink';
import { MobileSlider } from '../DataToView/Day/MobileSlider/MobileSlider';
import EditControls from './EditGoal/EditGoal';
import { TodayController } from './TodayController/TodayController';
import { HourlyAmounts } from '../DataToView/Day/Charts/HourlyAmounts/HourlyAmounts';
import { LiquidTypes } from '../DataToView/Day/LiquidTypes';
import { HourlyTypes } from '../DataToView/Day/Charts/HourlyTypes/HourlyTypes';
import { Achievements } from './Achievements/Achievements';
import classes from './Today.module.scss';
import '../../../Styles/main.scss';

/**
 * Component for displaying today controls and progress
 */
const Today: React.FC = () => {
  // get all necessary data to fill the view
  const today = useToday();

  // Toggled hourly amounts and types charts
  const chartsContent = (
    <div
      className={`${classes['charts-background']} ${classes['charts-container']} content-background ${today.boxShadow}`}
    >
      <div className={`${classes['toggle-buttons-container']}`}>
        <ToggleButtonGroup
          color="standard"
          size="small"
          value={today.toggleValue ? 'amounts' : 'types'}
          exclusive
          onChange={today.toggleButtonHandler}
        >
          <ToggleButton className="toggle-button" value="amounts">
            {today.translations.amount}
          </ToggleButton>
          <ToggleButton className="toggle-button" value="types">
            {today.translations.type}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {today.toggleValue ? (
        <HourlyAmounts day={today.day} />
      ) : (
        <HourlyTypes colors={today.COLORS} day={today.day} />
      )}
    </div>
  );

  const slides = [
    <div key={v4()}>{chartsContent}</div>,
    <div key={v4()}>
      <div
        className={`${classes['todays-types-background']} content-background ${today.boxShadow}`}
      >
        <LiquidTypes colors={today.COLORS} day={today.day} />
      </div>
    </div>,
    <div key={v4()}>
      <div
        className={`${classes['achievements-background']} content-background ${today.boxShadow}`}
      >
        <Achievements />
      </div>
    </div>,
  ];

  const charts = today.isMobile ? (
    <Grid item lg={6} xs={12}>
      <MobileSlider slides={slides} />
    </Grid>
  ) : (
    <>
      <Grid item lg={6} xs={12}>
        <div
          className={`${classes['achievements-background']} content-background ${today.boxShadow}`}
        >
          <Achievements />
        </div>
      </Grid>
      <Grid item lg={6} xs={12}>
        <div
          className={`${classes['todays-types-background']} content-background ${today.boxShadow}`}
        >
          <LiquidTypes colors={today.COLORS} day={today.day} />
        </div>
      </Grid>
      <Grid item lg={6} xs={12}>
        {chartsContent}
      </Grid>
    </>
  );

  return (
    <Grid item xs={12}>
      {today.showControlEdit && <EditControls closeEdit={today.closeEdit} />}
      {today.drink.drinkModal && (
        <Drink
          closeEdit={today.closeEdit}
          closeDrink={today.drink.closeDrinkModal}
        />
      )}
      <div
        className={`${classes['today-container']} ${today.contentBackground}`}
      >
        <Grid container>
          <Grid
            item
            lg={6}
            xs={12}
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <div
              className={`${classes['controls-background']} ${classes['controls-container']} content-background ${today.boxShadow}`}
            >
              <h1 className={classes['today']}>{today.todayTitle}</h1>
              <TodayController
                openControlEdit={today.openEdit}
                openDrinkModal={today.drink.openDrinkModal}
              />
            </div>
          </Grid>
          {charts}
        </Grid>
      </div>
    </Grid>
  );
};

export { Today };
