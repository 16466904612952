import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import classes from './LanguagePicker.module.scss';
import i18next from 'i18next';

enum Languages {
  en = 'EN',
  de = 'DE',
  ru = 'RU',
}

const LanguagePicker = () => {
  // Set state for language buttons
  const [language, setLanguage] = React.useState(
    // get language upper case signature from i18next
    i18next.language.split('-')[0].toLocaleUpperCase()
  );

  /**
   * handle language switch on button clicks
   */
  const handleLangChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    // get target languge
    const lang = event.currentTarget.innerText;

    // check for language signature and switch to another langauge
    switch (lang) {
      case Languages.de:
        i18next.changeLanguage(`de-DE`);
        setLanguage(Languages.de);
        break;
      case Languages.en:
        i18next.changeLanguage(`en-UK`);
        setLanguage(Languages.en);
        break;
      case Languages.ru:
        i18next.changeLanguage(`ru-RU`);
        setLanguage(Languages.ru);
        break;
    }
  };

  return (
    <ToggleButtonGroup
      className={classes['language-selection']}
      value={language}
      exclusive
      onChange={handleLangChange}
      aria-label="text alignment"
    >
      <ToggleButton
        className="toggle-button"
        value={Languages.en}
        aria-label="left aligned"
      >
        {Languages.en}
      </ToggleButton>
      <ToggleButton
        className="toggle-button"
        value={Languages.de}
        aria-label="centered"
      >
        {Languages.de}
      </ToggleButton>
      <ToggleButton
        className="toggle-button"
        value={Languages.ru}
        aria-label="right aligned"
      >
        {Languages.ru}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export { LanguagePicker };
