import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Hooks/Auth';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getDayFromMonth } from '../Watertracker/WaterTracker.utils';
import { getMonthData } from '../../Reducers/WaterTracker/WaterTracker.thunks';
import { getHeaders } from '../../Utils/Request.utils';
import { AppDispatch, RootState } from '../../Reducers/Store';
import { formatDateToString } from '../../Utils/Time.utils';
import RoutesValues from '../../Routes/Routes.values';
import { MonthIndex } from '../../Types/Global.types';

const useMenu = () => {
  const { month } = useSelector((state: RootState) => state.waterTracker);
  const { currentUser } = useAuth();

  // get translations
  const { t } = useTranslation();
  const translations = {
    close: t('menu.close'),
    tracker: t('menu.tracker'),
    archive: t('menu.archive'),
    logout: t('menu.logout'),
  };

  // Get dispatch for managing states
  const dispatch = useDispatch<AppDispatch>();

  // location for displaying the neccessary menu items
  const location = useLocation();

  // logout function for logout button
  const { logout } = useAuth();

  // navigate function for using on nav items
  const navigate = useNavigate();

  // get reference object for menu to detect clicks
  const menuRef = useRef<HTMLDivElement>(null);

  // state for menu open/close
  const [open, setOpen] = React.useState(false);

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  // observe clicks outside the menu
  React.useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // get current pathname
  const { pathname } = location;

  // open menu
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // close menu
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // logout user and navigate to sign in page
  const handleLogout = async () => {
    await logout();
    navigate(RoutesValues.signIn);
  };

  // navigate to archive page
  const navigateToArchive = async () => {
    // set time traverse values
    const now = new Date();
    const yearIndex = now.getFullYear();
    const monthIndex = now.getMonth();

    // get month data if it's neccessary
    const today = new Date(yearIndex, monthIndex, 1);
    const match = getDayFromMonth(month, today)?.date;
    if (!match && currentUser) {
      // get user token and headers
      const token = await currentUser.getIdToken();
      const headers = getHeaders(token);

      // dispatch get month action
      await dispatch(
        getMonthData({
          req: { year: yearIndex, month: monthIndex as MonthIndex },
          headers,
        })
      );
    }

    // navigate to archive
    navigate(`${RoutesValues.archive}/${formatDateToString(now, 'MM-YYYY')}`);
  };

  // navigate to tracker
  const navigateToTracker = async () => {
    // get month data if it's neccessary
    const today = new Date();
    const match = getDayFromMonth(month, today)?.date;

    // navigate to tracker page
    navigate(RoutesValues.tracker);

    if (!match && currentUser) {
      // get user token and headers
      const token = await currentUser.getIdToken();
      const headers = getHeaders(token);

      // dispatch get month action
      await dispatch(
        getMonthData({
          req: {
            year: today.getFullYear(),
            month: today.getMonth() as MonthIndex,
          },
          headers,
        })
      );
    }
  };

  return {
    pathname,
    menuRef,
    open,
    translations,
    navigateToArchive,
    navigateToTracker,
    handleLogout,
    handleDrawerOpen,
    handleDrawerClose,
  };
};

export { useMenu };
