import { Themes } from '../Constants';

/**
 * Set class to body based on theme
 * @param theme Theme to set
 */
function setThemeClass(theme: Themes) {
  let themeToSet = theme;

  // Converte system theme if needed
  if (theme === Themes.system) {
    themeToSet = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? Themes.dark
      : Themes.light;
  }

  // Check if theme is set to dark than set dark class to body
  if (themeToSet === Themes.dark && !document.body.classList.contains('dark')) {
    document.body.classList.add(Themes.dark);
  }

  // Check if theme is set to light than remove dark class from body
  if (themeToSet === Themes.light && document.body.classList.contains('dark')) {
    document.body.classList.remove(Themes.dark);
  }
}

/**
 * Get theme from local storage or system theme
 * @returns Themes string
 */
function getTheme(): Themes {
  // Get theme from local storage
  let theme = localStorage.getItem('theme') as Themes | null;

  // Check if theme is not set and set it
  if (!theme) {
    // Get system theme
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)')
      .matches
      ? Themes.dark
      : Themes.light;

    // Set theme to system theme
    theme = systemTheme;
  }

  setThemeClass(theme);

  return theme;
}

/**
 * Set theme to local storage and body class
 * @param theme Theme to set
 */
function setTheme(theme: Themes) {
  // Check if theme is set to system
  if (theme === Themes.system) {
    // Get system theme
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)')
      .matches
      ? Themes.dark
      : Themes.light;

    // Set theme class to body based on system theme
    setThemeClass(systemTheme);
  } else {
    // Set theme class to body based on incoming parameter
    setThemeClass(theme);
  }

  // Set theme to local storage
  localStorage.setItem('theme', theme);
}

export { getTheme, setTheme };
