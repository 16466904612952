import React from 'react';
import { useDrink } from './Drink.hook';
import { Button, InputAdornment, TextField } from '@mui/material';
import BluredOverlay from '../../../Overlays/BluredOverlay/BluredOverlay';
import classes from './Drink.module.scss';
import '../../../../Styles/main.scss';

interface DrinkProps {
  closeEdit: () => void;
  closeDrink: () => void;
}

/**
 * Drink modal component
 */
const Drink: React.FC<DrinkProps> = ({ closeEdit, closeDrink }) => {
  const drink = useDrink(closeEdit, closeDrink);

  return (
    <BluredOverlay>
      <div className={`${classes['modal-wrapper']} modal-background`}>
        <h3 className={`${classes['title']}`}>{drink.translations.title}</h3>

        <TextField
          className="mui-input"
          error={drink.validate && !drink.isAmountValid}
          label={drink.translations.drinkAmount.description}
          variant="standard"
          helperText={drink.translations.drinkAmount.hint}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {drink.translations.ml}
              </InputAdornment>
            ),
          }}
          value={drink.amount}
          fullWidth
          margin="dense"
          autoComplete="off"
          autoCorrect="off"
          onChange={drink.handleChangeAmount}
        />

        <TextField
          className="mui-input"
          error={!drink.isTypeValid}
          helperText={drink.translations.liquidType.hint}
          value={drink.type ? drink.type : ''}
          onChange={drink.handleChangeType}
          label={drink.translations.liquidType.description}
          variant="standard"
          fullWidth
          autoComplete="off"
          autoCorrect="off"
          margin="dense"
        />

        <div className={`${classes['control-buttons-container']} mt-4`}>
          <Button
            className="mui-button"
            onClick={drink.handleSubmit}
            variant="outlined"
          >
            {drink.translations.buttons.add}
          </Button>

          <Button
            className="mui-button"
            onClick={drink.handleCancelDrink}
            variant="outlined"
          >
            {drink.translations.buttons.close}
          </Button>
        </div>
      </div>
    </BluredOverlay>
  );
};

export { Drink };
