import React from 'react';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useScreenWidth } from '../../../../Providers/ScreenWidth';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Divider, Grid, List, ListItem } from '@mui/material';
import { ChartIsLoading, NoData } from './Charts/Charts';
import { setLiquidTypes } from '../../Today/Today.utils';
import { RootState } from '../../../../Reducers/Store';
import { SingleDayData } from '../../../../Types/WaterTracker.types';
import { BREAKPOINT_SLIDER } from './Charts/Charts.constants';
import classes from './Charts/Charts.module.scss';
import '../../../../Styles/main.scss';

interface ChartLegendListProps {
  data: {
    id: string;
    label: string;
    value: number;
    color: string;
  }[];
}

const ChartLegendList: React.FC<ChartLegendListProps> = ({ data }) => {
  const { screenWidth } = useScreenWidth();

  // Get translations
  const { t } = useTranslation();
  const ml = t('measuringUnits.ml');

  const list = (
    <List
      className={`${classes['list']} ${
        BREAKPOINT_SLIDER < screenWidth && classes['scroll-way']
      }`}
    >
      {data.map((item, index, arr) => (
        <React.Fragment key={v4()}>
          <ListItem className={`${classes['list-item']}`}>
            <div className={`${classes['type-container']}`}>
              <div
                className={`${classes['color-box']}`}
                style={{ backgroundColor: item.color }}
              ></div>
              <span>{item.label}</span>
            </div>

            <span>{`${item.value} ${ml}`}</span>
          </ListItem>
          {index < arr.length - 1 && (
            <Divider
              className="mui-divider"
              orientation={'horizontal'}
              variant={'middle'}
              component={'li'}
            />
          )}
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <div
      className={`${classes['list__background']} highlighted-content-background`}
    >
      <OverlayScrollbarsComponent
        className="mx-2"
        element="div"
        defer
        options={{
          scrollbars: {
            dragScroll: true,
            clickScroll: 'instant',
            visibility: 'visible',
          },
          showNativeOverlaidScrollbars: false,
        }}
      >
        <div className={`${classes['legend-container']}`}>{list}</div>
      </OverlayScrollbarsComponent>
    </div>
  );
};

interface LiquidTypesProps {
  colors: string[];
  day: SingleDayData | null;
}

/**
 * Liquid Types Component: displays the liquid types and their amounts in a pie chart
 */
const LiquidTypes: React.FC<LiquidTypesProps> = ({ day }) => {
  const { contentIsLoading, initialLoading } = useSelector(
    (state: RootState) => state.waterTracker
  );

  // Set up the data for the list
  const data = setLiquidTypes(day);

  // Boolean for activating loading spinner
  const isLoading = contentIsLoading || initialLoading;

  // Get translations
  const { t } = useTranslation();
  const translations = {
    chartTitle: t('tracker.todayPanel.liquidTypes.chartTitle'),
    listTitle: t('tracker.todayPanel.liquidTypes.listTitle'),
    ml: t('measuringUnits.ml'),
    hours: t('timeUnits.hours'),
    message: t('tracker.emptyMessage'),
  };

  const contentOnLoading = isLoading ? (
    <Grid item xs={12}>
      <ChartIsLoading />
    </Grid>
  ) : (
    <Grid item xs={12}>
      {data.length <= 0 && <NoData emptyMessage={translations.message} />}
      <ChartLegendList data={data} />
    </Grid>
  );

  return (
    <div className={`${classes['container']} `}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes['title-container']}>
            <h3 className={`${classes['title']}`}>{translations.chartTitle}</h3>
          </div>
        </Grid>
        <Grid item xs={12}>
          {contentOnLoading}
        </Grid>
      </Grid>
    </div>
  );
};

export { LiquidTypes };
