import React from 'react';
import { useSignIn } from './SignIn.hooks';
import { useRedirectToProtected } from '../../../Hooks/Redirect';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { LanguagePicker } from '../../UI/LanguagePicker/LanguagePicker';
import { ThemePicker } from '../../UI/ThemePicker/ThemePicker';
import LoadingOverlay from '../../Overlays/LoadingOverlay/LoadingOverlay';
import RoutesValues from '../../../Routes/Routes.values';
import { boxStyles } from '../Athentication.constants';
import classes from '../Authentication.module.scss';
import '../../../Styles/main.scss';

/**
 * Sign in component for user
 */
const SignIn: React.FC = () => {
  // get object with all sign in functions and states
  const signIn = useSignIn();

  useRedirectToProtected();

  return !signIn.currentUser && signIn.userIsLoading ? (
    <LoadingOverlay />
  ) : (
    <div className={`${classes['relative']}`}>
      <CssBaseline />
      <Box sx={boxStyles}>
        <Box
          component="form"
          noValidate
          onSubmit={signIn.handleSubmit}
          sx={{ mt: 1 }}
        >
          <TextField
            className="mui-input"
            variant="standard"
            {...signIn.emailInputProps}
          />
          <TextField
            className="mui-input"
            variant="standard"
            {...signIn.passwordInputProps}
          />
          <FormControlLabel
            className="mui-input"
            control={<Checkbox {...signIn.rememberMeInputProps} />}
            label={signIn.translations.rememberMe}
          />
          <Button
            className="mui-button"
            type="submit"
            fullWidth
            variant="outlined"
            sx={{ mt: 3 }}
          >
            {signIn.translations.signin}
          </Button>
          <Grid container justifyContent={'end'}>
            <Grid item sx={{ mt: 2 }}>
              <NavLink to={RoutesValues.signUp}>
                {signIn.translations.question}
              </NavLink>
            </Grid>
          </Grid>
          {signIn.errorMessage && (
            <div className={classes['error-message']}>
              {signIn.translations.invalidInput}
            </div>
          )}
        </Box>
      </Box>
      <ThemePicker />
      <LanguagePicker />
    </div>
  );
};

export default SignIn;
