import React from 'react';
import { useScreenWidth } from '../../../../Providers/ScreenWidth';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FinishedDaysBadge } from './FinishedDaysBadge';
import { DrinksBadge } from './DrinksBadge';
import { Grid, Skeleton } from '@mui/material';
import { RootState } from '../../../../Reducers/Store';
import { BREAKPOINT_SLIDER } from '../../DataToView/Day/Charts/Charts.constants';
import { AmountBadge } from './AmountBadge';
import classes from './Achievements.module.scss';
import '../../../../Styles/main.scss';

const SCREEN_SIZE_MEDIUM = 920;

const Achievements: React.FC = () => {
  // Get translations
  const { t } = useTranslation();
  const translations = {
    title: t('tracker.allTimeStatistics.title'),
    l: t('measuringUnits.l'),
  };

  // Get screen width
  const { screenWidth } = useScreenWidth();
  const isMobile = screenWidth < BREAKPOINT_SLIDER;

  // Get data from redux
  const { achievements, contentIsLoading, initialLoading } = useSelector(
    (state: RootState) => state.waterTracker
  );

  // Total amount need to be divided by 1000 to get liters
  const amountValue = achievements?.totalAmount
    ? achievements.totalAmount / 1000
    : 0;

  // Loading indicator
  const isLoading = contentIsLoading || initialLoading;

  // Statistics values
  const amount = `= ${
    achievements?.totalAmount ? achievements.totalAmount / 1000 : 0
  } ${translations.l}`;
  const drinks = `x ${
    achievements?.totalDrinkCounter ? achievements.totalDrinkCounter : 0
  }`;
  const days = `x ${
    achievements?.totalDaysFinished ? achievements.totalDaysFinished : 0
  }`;

  // Loading elements
  const loading = (
    <Skeleton
      className="mui-skeleton"
      variant="rounded"
      height={'1.5rem'}
      width={'3rem'}
    />
  );

  // Statistics elements
  const amountElement = isLoading ? loading : amount;
  const drinksElement = isLoading ? loading : drinks;
  const daysElement = isLoading ? loading : days;

  return (
    <div className={`${classes['container']}`}>
      {!isMobile && (
        <div
          className={`${classes['statistics-container']} ${
            screenWidth > SCREEN_SIZE_MEDIUM && 'highlighted-content-background'
          }`}
        >
          <h3 className={`${classes['title']}`}>{translations.title}</h3>
          <Grid container>
            <Grid item sm={4} xs={12}>
              <div className={`${classes['statistics-item']}`}>
                <div
                  className={`${classes['content']} ${
                    !isMobile && classes['border-right']
                  } divider-color`}
                >
                  <svg
                    className={`${classes['icon']} icon`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path d="M269.5 69.9c11.1-7.9 25.9-7.9 37 0C329 85.4 356.5 96 384 96c26.9 0 55.4-10.8 77.4-26.1c0 0 0 0 0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 149.7 417 160 384 160c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4C42.8 92.6 61 83.5 75.3 71.6c11.1-9.5 27.3-10.1 39.2-1.7c0 0 0 0 0 0C136.7 85.2 165.1 96 192 96c27.5 0 55-10.6 77.5-26.1zm37 288C329 373.4 356.5 384 384 384c26.9 0 55.4-10.8 77.4-26.1c0 0 0 0 0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 437.7 417 448 384 448c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4c18.1-4.2 36.2-13.3 50.6-25.2c11.1-9.4 27.3-10.1 39.2-1.7c0 0 0 0 0 0C136.7 373.2 165.1 384 192 384c27.5 0 55-10.6 77.5-26.1c11.1-7.9 25.9-7.9 37 0zm0-144C329 229.4 356.5 240 384 240c26.9 0 55.4-10.8 77.4-26.1c0 0 0 0 0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 293.7 417 304 384 304c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4c18.1-4.2 36.2-13.3 50.6-25.2c11.1-9.5 27.3-10.1 39.2-1.7c0 0 0 0 0 0C136.7 229.2 165.1 240 192 240c27.5 0 55-10.6 77.5-26.1c11.1-7.9 25.9-7.9 37 0z" />
                  </svg>
                  {amountElement}
                </div>
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div className={`${classes['statistics-item']} `}>
                <div
                  className={`${classes['content']} ${
                    !isMobile && classes['border-right']
                  } divider-color`}
                >
                  <svg
                    className={`${classes['icon']} icon`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path d="M32 0C23.1 0 14.6 3.7 8.6 10.2S-.6 25.4 .1 34.3L28.9 437.7c3 41.9 37.8 74.3 79.8 74.3l166.6 0c42 0 76.8-32.4 79.8-74.3L383.9 34.3c.6-8.9-2.4-17.6-8.5-24.1S360.9 0 352 0L32 0zM73 156.5L66.4 64l251.3 0L311 156.5l-24.2 12.1c-19.4 9.7-42.2 9.7-61.6 0c-20.9-10.4-45.5-10.4-66.4 0c-19.4 9.7-42.2 9.7-61.6 0L73 156.5z" />
                  </svg>
                  {drinksElement}
                </div>
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div className={`${classes['statistics-item']}`}>
                <div className={`${classes['content']}`}>
                  <svg
                    className={`${classes['icon']} icon`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M32 0C49.7 0 64 14.3 64 32l0 16 69-17.2c38.1-9.5 78.3-5.1 113.5 12.5c46.3 23.2 100.8 23.2 147.1 0l9.6-4.8C423.8 28.1 448 43.1 448 66.1l0 279.7c0 13.3-8.3 25.3-20.8 30l-34.7 13c-46.2 17.3-97.6 14.6-141.7-7.4c-37.9-19-81.3-23.7-122.5-13.4L64 384l0 96c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-80 0-66L0 64 0 32C0 14.3 14.3 0 32 0zM64 187.1l64-13.9 0 65.5L64 252.6 64 318l48.8-12.2c5.1-1.3 10.1-2.4 15.2-3.3l0-63.9 38.9-8.4c8.3-1.8 16.7-2.5 25.1-2.1l0-64c13.6 .4 27.2 2.6 40.4 6.4l23.6 6.9 0 66.7-41.7-12.3c-7.3-2.1-14.8-3.4-22.3-3.8l0 71.4c21.8 1.9 43.3 6.7 64 14.4l0-69.8 22.7 6.7c13.5 4 27.3 6.4 41.3 7.4l0-64.2c-7.8-.8-15.6-2.3-23.2-4.5l-40.8-12 0-62c-13-3.8-25.8-8.8-38.2-15c-8.2-4.1-16.9-7-25.8-8.8l0 72.4c-13-.4-26 .8-38.7 3.6L128 173.2 128 98 64 114l0 73.1zM320 335.7c16.8 1.5 33.9-.7 50-6.8l14-5.2 0-71.7-7.9 1.8c-18.4 4.3-37.3 5.7-56.1 4.5l0 77.4zm64-149.4l0-70.8c-20.9 6.1-42.4 9.1-64 9.1l0 69.4c13.9 1.4 28 .5 41.7-2.6l22.3-5.2z" />
                  </svg>
                  {daysElement}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      <Grid container>
        <Grid item sm={4} xs={12}>
          <AmountBadge value={amountValue} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <DrinksBadge value={achievements?.totalDrinkCounter || 0} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FinishedDaysBadge value={achievements?.totalDaysFinished || 0} />
        </Grid>
      </Grid>
    </div>
  );
};

export { Achievements };
